/* Webfont: AvenirNext-Bold */
@font-face {
    font-family: 'AvenirNext-Bold';
    src: url('./assets/fonts/Avenir-Next/AvenirNext-Bold.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/Avenir-Next/AvenirNext-Bold.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/Avenir-Next/AvenirNext-Bold.woff')
            format('woff'),
        /* Modern Browsers */
            url('./assets/fonts/Avenir-Next/AvenirNext-Bold.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/Avenir-Next/AvenirNext-Bold.svg#AvenirNext-Bold')
            format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AvenirNext-DemiBold */
@font-face {
    font-family: 'AvenirNext-DemiBold';
    src: url('./assets/fonts/Avenir-Next/AvenirNext-DemiBold.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/Avenir-Next/AvenirNext-DemiBold.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/Avenir-Next/AvenirNext-DemiBold.woff')
            format('woff'),
        /* Modern Browsers */
            url('./assets/fonts/Avenir-Next/AvenirNext-DemiBold.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/Avenir-Next/AvenirNext-DemiBold.svg#AvenirNext-DemiBold')
            format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'AvenirNext-Medium';
    src: url('./assets/fonts/Avenir-Next/AvenirNext-Medium.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/Avenir-Next/AvenirNext-Medium.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/Avenir-Next/AvenirNext-Medium.woff')
            format('woff'),
        /* Modern Browsers */
            url('./assets/fonts/Avenir-Next/AvenirNext-Medium.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/Avenir-Next/AvenirNext-Medium.svg#AvenirNext-Medium')
            format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

@font-face {
    font-family: 'AvenirNext-Regular';
    src: url('./assets/fonts/Avenir-Next/AvenirNext-Regular.eot'); /* IE9 Compat Modes */
    src: url('./assets/fonts/Avenir-Next/AvenirNext-Regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/Avenir-Next/AvenirNext-Regular.woff')
            format('woff'),
        /* Modern Browsers */
            url('./assets/fonts/Avenir-Next/AvenirNext-Regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/Avenir-Next/AvenirNext-Regular.svg#AvenirNext-Regular')
            format('svg'); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/Lato/Lato-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./assets/fonts/Lato/Lato-regular.eot?#iefix')
            format('embedded-opentype'),
        /* IE6-IE8 */ url('./assets/fonts/Lato/Lato-regular.woff2')
            format('woff2'),
        /* Super Modern Browsers */ url('./assets/fonts/Lato/Lato-regular.woff')
            format('woff'),
        /* Modern Browsers */ url('./assets/fonts/Lato/Lato-regular.ttf')
            format('truetype'),
        /* Safari, Android, iOS */
            url('./assets/fonts/Lato/Lato-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 10px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
