.created-icon {
    background-color: #0e9ef3;
    border-radius: 9px;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pending-icon {
    background-color: #f5a623;
    border-radius: 9px;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
}
