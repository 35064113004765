.base {
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    width: 40vw;
    height: 50px;
    max-width: 370px;
    min-width: 300px;
    /* margin-bottom: 10px; */
}

.ant-input::placeholder {
    color: #8e929a;
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
}

.fbo-address-input {
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    height: 50px;
    width: 75vw;
    min-width: 450px;
    max-width: 770px;
}

.btn-wrapper {
    margin-top: 15px;
}

.fbo-display-name {
    margin-left: 10px;
    margin-top: 10px;
}

.left {
    margin-right: 30px;
}

.wrapper {
    padding-bottom: 25px;
    margin-top: 15px;
}

.wrapper-info {
    background-color: #fff;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.identity-none {
    background-color: #fff;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 15px;
}

.content {
    margin-left: 25px;
    margin-right: 25px;
    align-self: center;
}

.form-label {
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-align: left;
}

.status {
    margin-left: 5px;
    margin-bottom: 5px;
}

.col-general {
    min-height: 128px;
}

.add-fbo-item {
    margin-left: 50px;
    margin-top: 5px;
}

.label-detail {
    width: 35%;
    display: flex;
    justify-content: space-between;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid rgba(36, 36, 45, 0.15);
    max-width: 770px;
    min-width: 240px;
    /* width: auto; */
    height: 50px;
    border-radius: 3px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-selection-item {
    align-self: center;
}

.ant-tabs-nav-list {
    border-bottom: gray !important;
    border-width: 2px !important;
}
