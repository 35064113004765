.address {
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    width: 770px;
    height: 50px;
}

.right {
    margin-left: 30px;
}

.wrapper {
    padding-bottom: 25px;
    margin-top: 15px;
}

.content {
    /* margin-left: 25px;
    margin-right: 25px; */
    align-self: flex-end;
}

.form-label {
    color: #6c6f76;
    font-family: AvenirNext-DemiBold;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-align: left;
}
