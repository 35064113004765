.wrapper {
    padding: 25px;
}

.content {
    margin-left: 25px;
    margin-right: 25px;
}

.detail-item {
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 50%;
    justify-content: space-between;
}

.detail-item-full {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 10px;
    width: 65%;
    justify-content: space-between;
}

.item-uploader {
    background-color: #f6f9fc;
    border-radius: 4px;
    width: 202px;
    height: 127px;
}

.input-item {
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    width: 270px;
    height: 50px;
    padding-top: 8px;
}

.input-add-item {
    border: 1px solid rgba(36, 36, 45, 0.15);
    border-radius: 3px;
    width: 370px;
    height: 50px;
    padding-top: 8px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid rgba(36, 36, 45, 0.15);
    max-width: 770px;
    min-width: 240px;
    /* width: auto; */
    height: 50px;
    border-radius: 3px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.item-image {
    background-size: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
    width: 270px;
    height: 170px;
}

.ant-switch-checked {
    background-color: #19c0ff;
}

.add-service-item {
    margin-left: 50px;
    margin-top: 30px;
}

.section-padding {
    margin-top: 40px;
}

.row-detail-service {
    margin-left: 25px;
    margin-top: 25px;
}

.row-edit-service {
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 15px;
}
