@import '~antd/dist/antd.less';
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-avatar-custom {
    color: #19c0ff;
    background-color: #ddf6ff;
    font-weight: 500;
    font-size: 12px;
}

.ant-avatar-custom-big {
    font-size: 28px !important;
    height: 120px !important;
    width: 120px !important;
    border-radius: 50% !important;
    display: flex;
    align-items: center;
}

.ant-modal-close {
    background-color: #f0f3f9;
    border-radius: 100%;
    width: 32px;
    height: 32px;
    top: -16px;
    right: -16px;
}

.ant-modal-close-x {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-modal-custom {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 17px 20px 0 rgba(0, 0, 0, 0.1);
}

.modal-create-package {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 17px 20px 0 rgba(0, 0, 0, 0.1);
    width: 694px !important;
    height: 364px !important;
}

.ant-modal-content {
    height: 100%;
    box-shadow: none;
    border-radius: 8px;
}

.modal-loading-page .ant-modal-content {
    background-color: transparent;
}

.ant-modal-body {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.upload-image-section {
    background-color: #f6f9fc;
    border-radius: 4px;
    width: 202px;
    height: 127px;
}

.upload-avatar-section {
    background-color: #f6f9fc;
    border-radius: 100%;
    width: 120px;
    height: 120px;
}

.ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: top;
    background-color: transparent;
    border: none;
    border-radius: 2px;
    cursor: pointer;
}

.ant-drawer-content-wrapper {
    width: 270px;
    margin-top: 80px;
}

.ant-drawer-mask {
    background-color: transparent;
}

.ant-table-thead > tr > th {
    background-color: #f7f8fb;
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background: #f7f8fb;
}

/* .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
    border-radius: 100% !important; 
} */

.ant-pagination-item {
    border-width: 0px;
    font-family: AvenirNext-DemiBold;
    padding-top: 2px;
    color: #65686f;
    font-size: 14px;
}

.ant-pagination-item-active a {
    color: #19c0ff;
}

.ant-table-cell {
    padding: 12px !important;
}

.ant-select-item-option:hover {
    background-color: #f8f9fe;
}

.ant-select-selection-item {
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    align-self: center;
}

.ant-select-item-option-content {
    color: #65686f;
    font-family: AvenirNext-Medium;
    font-size: 13px;
    line-height: 16px;
    align-self: center;
}

.ant-select-item-option .ant-select-item-option-content:hover {
    color: #0e0e12;
}

.ant-pagination-item-active {
    border-width: 1px !important;
    border-color: #19c0ff !important;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
    border-color: #19c0ff;
    border-width: 1px;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
    color: #19c0ff;
}

.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #19c0ff;
}

.ant-menu-light .ant-menu-item:hover {
    color: #19c0ff;
}

.ant-menu-item-selected > .ant-menu-title-content {
    color: #19c0ff;
}

.ant-popover-inner-content {
    padding: 0px;
}

.dashboardMapPopover .ant-popover-arrow .ant-popover-arrow-content {
    height: 0;
}
.dashboardMapPopover {
    padding-bottom: 0px !important;
}
.calendarPopover .ant-popover-content {
    border-top: 2px solid #19c0ff !important;
}

.calendarPopover .ant-popover-content .ant-popover-inner .ant-popover-title {
    padding: 0;
    display: flex;
    align-items: center;
    border-bottom: none;
    /* padding: 5px 10px; */
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
    background-color: #19c0ff;
}

.rbc-overlay-header {
    font-family: AvenirNext-DemiBold;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
    outline: 5px auto #19c0ff;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
    background-color: #19c0ff;
}

@media (max-width: 1024px) {
    .ant-table-pagination-right {
        justify-content: center;
    }
}

.ant-tooltip-inner {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
    color: #35383f;
    font-family: AvenirNext-Medium;
    font-size: 11px;
    line-height: 14px;
}

.ant-tooltip-arrow-content {
    background-color: #ffffff;
}

.ant-select-dropdown {
    border-radius: 0 0 4px 4px;
}
@primary-color: #19c0ff;