.wrapper {
    padding-bottom: 25px;
    margin-top: 15px;
}

.content {
    margin-left: 25px;
    margin-right: 25px;
}

.avatar-item {
    background-size: 100%;
    border-radius: 100%;
    width: 152px;
    height: 152px;
    margin-left: 25px;
    margin-right: 25px;
}

.ant-tabs-ink-bar {
    background: #19c0ff !important;
}

.display-name {
    margin-left: 15px;
    margin-top: 5px;
}
