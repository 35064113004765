.ant-tag-custom {
    border: none;
    background-color: #f8f9fe;
    border-radius: 7px;
    /* width: 95px; */
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.ant-slider-with-marks {
    margin-bottom: 28px;
}

.ant-slider-rail {
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #f0f3f9;
    border-radius: 2px;
    transition: background-color 0.3s;
}

.ant-slider-track {
    position: absolute;
    height: 6px;
    background-color: #19c0ff;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.ant-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    background-color: #0fb0ed;
    border: solid 2px #0fb0ed;
    border-radius: 50%;
    /* box-shadow: 0; */
    box-shadow: 0 4px 6px 0 rgba(29, 29, 36, 0.2);
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s,
        transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.ant-slider-dot {
    position: absolute;
    top: 1px;
    width: 4px;
    height: 4px;
    margin-left: -4px;
    background-color: transparent !important;
    border: none !important;
    /* border-radius: 50%; */
    cursor: pointer;
}
